import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
const Constants = require('./config.js')

function toggleDarkMode() {
    document.body.classList.toggle('dark');
    if (document.body.classList.contains('dark')) {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', '#02020a');
        localStorage.setItem("dark-mode", "dark");
    }
    else {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', '#ffffff');
        localStorage.setItem("dark-mode", "light");
    }
}

class Footer extends React.Component{
    render(){
    const links = Constants.footer_links.map((link) => {
        return <div className="inline-block"><a className="invertor" target="_blank" href={link.url}>{link.name}</a> </div>;
    })
        return(
            <div className="container">
                <div className="footer text-center">
                    <br/>
                    <br/>
                    {links}
                    <br/>
                    <br/>
                    <b>
                        Designed & Developed by
                        <br/>
                        <a target='_blank' href='https://apurva.xyz' className='credits'>
                            Apurva N Saraogi
                        </a>
                    </b>
                    <br/>
                    <br/>
                </div>
            </div>    
        )
    }
}

class Project extends React.Component {
    render(){
        let link = <b>{this.props.elements.name}</b>
        if (this.props.elements.url) {
            link = <b>
                    <a className="normal-link" target="_blank" href={this.props.elements.url}>
                    {this.props.elements.name}
                    </a>
                </b>
        }
        const listitems = this.props.elements.skills.join(" • ")
        return (
            <div className="project">
                {link}
                <br/>
                <span className="skill-pill">{listitems}</span>
            </div>
        )
    }
}

class Skillset extends React.Component {
    render(){
        const skills = this.props.elements.skills.join(" • ")
        return(
            <React.Fragment>
                <div className="col-12 col-md-4">
                    <b>{this.props.elements.heading}</b>
                </div>
                <div className="col-12 col-md-8">
                    {skills}
                </div>
                <div className="col-12 spacer"></div>
            </React.Fragment>
        )
    }
}

class Experience extends React.Component {
    render(){
        let link = <b>{this.props.elements.name}</b>
        if (this.props.elements.url) {
            link = <b>
                <a className="normal-link" target="_blank" href={this.props.elements.url}>
                    {this.props.elements.name}
                </a>
            </b>
        }
        let time = <span>{this.props.elements.end}</span>;
        if (this.props.elements.start) {
            time = <span> {this.props.elements.start} - {this.props.elements.end} </span>
        }

        return (
            <div className="education">
                <div className="row">
                    <div className="col-12 col-md-8">
                        {link}
                    </div>
                    <div className="col-12 col-md-4 text-right-md">
                        {time}
                    </div>
                    <div className="col-12 col-md-12">
                        {this.props.elements.subName}
                        <br/>
                        {this.props.elements.extraInfo}
                    </div>
                </div>
            </div>
        )
    }
}

const components = {
    'experience': Experience,
    'project': Project,
    'skills': Skillset,
}

function Section(props){
    let listview = <ListView type={props.type} className={props.className} list={props.list} />
    if(props.withRow){
        listview = <div className="row">{listview}</div>
    }

    return (
        <section id="experience">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-3 heading">
                        {props.title}
				    </div>
                    <div className="hidden-sm hidden-xs col-md-1"></div>
                    <div className="col-12 col-md-7">
                        {listview}
                    </div>
                </div>
            </div>
        </section>

    )
}

function ListView(props){
    const BaseElement = components[props.type];
    const listitems = props.list.map((element) => <BaseElement elements={element}/>);
    return (
        <React.Fragment>
            {listitems}
        </React.Fragment>
    )
}

function Home(props){
    return(
        <React.Fragment>
            <div className="dark-mode" onClick={toggleDarkMode}>
                <div id="toggle-circle">
                    <div id="toggle-left">
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 order-md-12  holder">
                        <div className="row">
                            <div className="col-3 col-md-2"></div>
                            <div className="col-6 col-md-8 holder">
                                <br/>
                                <img src={Constants.photograph} className="img-bulge profile-image vcenter img-fluid"/>
                                <br/>
                                <br/>
                            </div>
                            <div className="col-3 col-md-2"></div>
                        </div>
                    </div>
                    <div className="col-md-6 order-md-1">
                        <div className="vcenter">
                            <h1>Hello</h1>
                            <br/>
                            <h1>
                                I'm <b>Apurva N Saraogi</b>
                                <br/>
                            </h1>
                            <h3>I love making things that matter.</h3>
                            <br/>
                            <h4>See my <a target="_blank" className="invertor" href={Constants.resume}>Resume</a>.</h4>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

class Root extends React.Component{

    componentDidMount(){
        if (localStorage.getItem("dark-mode")) {
            if (localStorage.getItem("dark-mode") === "dark") {
                toggleDarkMode();
            }
        }
        else {
            localStorage.setItem("dark-mode", "light");
        }
    }

    render(){
        return(
            <React.Fragment>
                <Home/>
                <Section title={'EXPERIENCE'} type={'experience'} className={'education'} list={Constants.experience}/>
                <Section title={'EDUCATION'} type={'experience'} className={'education'} list={Constants.education}/>
                <Section title={'SKILLS'} type={'skills'} withRow={true} className={'education'} list={Constants.skillset}/>
                <Section title={'PROJECTS'} type={'project'} className={'projects'} list={Constants.projects}/>
                <Footer/>
            </React.Fragment>
        )
    }
}

ReactDOM.render(
    <Root/>,
    document.getElementById('root')
);