module.exports = {
    "name" : "Apurva N Saraogi",
    "footer_links" : [
        {
            'name': 'email',
            'url': 'mailto:apurva.saraogi9@gmail.com'
        },
        {
            'name': 'github',
            'url': 'https://github.com/apurva91'
        },
        {
            'name': 'codeforces',
            'url': 'https://codeforces.com/profile/apurva9'
        },
        {
            'name': 'stackoverflow',
            'url': 'https://stackoverflow.com/users/7699615/apurva-n-saraogi'
        },
        {
            'name': 'linkedin',
            'url': 'https://www.linkedin.com/in/apurva9/'
        },
        {
            'name': 'instagram',
            'url': 'https://www.instagram.com/i_m_apurva/'
        },
        {
            'name': 'facebook',
            'url': 'https://facebook.com/apurva.saraogi'
        },
        {
            'name': 'medium',
            'url': 'https://medium.com/@apurvansaraogi'
        },
        {
            'name': 'Quora',
            'url': 'https://www.quora.com/profile/Apurva-N-Saraogi'
        },
        {
            'name': 'twitter',
            'url': 'https://twitter.com/@apurva_saraogi'
        }
    ],
    "photograph" : "profile.jpg",
    "resume":"CV.pdf",
    "experience": [
        {
            'name': 'D. E. Shaw India Private Limited',
            'url': 'https://deshawindia.com',
            'start': '2020',
            'end': 'Present',
            'subName': 'Member of Technical',
        },
        {
            'name': 'Samsung Research Institute',
            'url': 'https://research.samsung.com/sri-b',
            'start': 'May 2019',
            'end': 'July 2019',
            'subName': 'Software Developer Intern, Networks',
        },
        {
            'name': 'Techniche, IIT Guwahati',
            'url': 'https://techniche.org',
            'start': 'Sep 2018',
            'end': 'Sep 2019',
            'subName': 'Web Operations Head',
        },
        {
            'name': 'Inter IIT Sports Meet',
            'url': 'https://interiit.com',
            'start': 'Aug 2018',
            'end': 'Dec 2018',
            'subName': 'Developer Intern',
        },
        {
            'name': 'Technothlon, IIT Guwahati',
            'url': 'https://technothlon.techniche.org.in',
            'start': 'Sep 2017',
            'end': 'Sep 2018',
            'subName': 'Cheif Organizer & Web Operations Head',
        }
    ],
    "education" : [
        {
            'name': 'Indian Institute of Technology, Guwahati',
            'url': 'https://iitg.ac.in',
            'start': '2016',
            'end': '2020',
            'subName': 'B. Tech. in Computer Science & Engineering',
            'extraInfo': 'GPA: 8.25',
        },
        {
            'name': 'Sacred Heart Sr Sec School, Kota',
            'end': '2016',
            'subName': 'CBSE Board, 12th Grade',
            'extraInfo': 'Percentage: 91.2%',
        },
        {
            'name': 'Nand Vidya Niketan',
            'url': 'http://nvnessar.edu.in/',
            'end': '2014',
            'subName': 'CBSE Board, 10th Grade',
            'extraInfo': 'Percentage: 91.2%',
        },       
    ],
    "projects": [
        {
            "name": "The Labs - Intranet Code Judge",
            "skills": ["Django", "Python", "VueJS", "Docker"],
            "url": "/labs.pdf"
        },
        {
            "name": "Diabetic Retinopathy Detection",
            "skills": ["Python", "Computer Vision", "Deep Learning"],
            "url": "https://github.com/Daman98/Diabetic-Retinopathy-Detection"
        },
        {
            "name": "Compilers",
            "skills": ["C++", "Flex", "Bison"],
            "url": "https://github.com/apurva91/Compilers"
        },
        {
            "name": "Shared Digital Indentity Unification",
            "skills": ["Blockchain", "Django"],
        },
        {
            "name": "Smart Classroom",
            "skills": ["IoT", "Arduino", "Raspberry Pi", "MQTT"],
            "url": "https://github.com/apurva91/Smart-Classroom"
        },
        {
            "name": "Verbose The Indexer",
            "skills": ["Python", "GTK", "Search Engine", "TF-IDF"],
            "url": "https://github.com/apurva91/Verbose-The-Indexer"
        },
        {
            "name": "Smart Faculty Portal",
            "skills": ["Django", "Web Scraping"],
            "url": "https://github.com/apurva91/Teachers-Portal"
        },
        {
            "name": "Fluxo De Energia",
            "skills": ["Laravel", "AJAX"],
            "url": "https://github.com/apurva91/fluxo-de-energia"
        },
        {
            "name": "Smart Parking System",
            "skills": ["Django", "Arduino", "IoT"],
            "url": "https://github.com/apurva91/Smart-Parking-System"
        },
        {
            "name": "Tigrow",
            "skills": ["Django"],
            "url": "https://github.com/apurva91/Tigrow"
        }
    ],
    'skillset': [
        {
            'heading': 'Programming Languages',
            'skills': ['C', 'C++', 'Python', 'JavaScript', 'Java', 'Golang', 'PHP']
        },
        {
            'heading': 'Web Technologies',
            'skills': ['Django', 'Laravel', 'NodeJS', 'ReactJS', 'Socket.IO', 'jQuery', 'VueJS']
        },
        {
            'heading': 'App Technologies',
            'skills': ['Flutter', 'Cordova']
        },
        {
            'heading': 'Databases',
            'skills': ['mySQL', 'mongoDB', 'Firebase']
        },
        {
            'heading': 'Miscellaneous',
            'skills': ['git', 'Raspberry Pi', 'Arduino', 'OpenCV', 'GTK', 'Docker']
        },
    ]
}
